<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->

        <!-- Panel start here -->
        <panel title="Form Edit Data Pegawai" class="panel panel-success">
            <!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> FORM INPUT BENTUK BADAN</h6>
            </template> -->
            <div class="pl-4 py-2 border rounded shadow-sm">
                <b-button
                    variant="primary"
                    class="px-4"
                    to="/file-master/pegawai/list"
                >
                    <i class="fa fa-arrow-circle-left pr-2"></i>
                    Kembali
                </b-button>
            </div>
            <div class="py-3">
                <b-card class="shadow-sm">
                    <h3 class="py-2">Data Pegawai</h3>
                    <hr />
                    <b-form
                        @submit.prevent="update"
                        @keydown="form.onKeydown($event)"
                        autocomplete="off"
                    >
                        <input type="hidden" name="_token" :value="csrf" />
                        <b-alert v-if="message" variant="danger" show
                            >Data yang anda input tidak valid.</b-alert
                        >
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >NIK <span class="text-danger">*</span></label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <input
                                            type="text"
                                            v-model="form.nik"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': form.errors.has(
                                                    'nik'
                                                ),
                                            }"
                                            placeholder="Masukkan NIK"
                                        />
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('nik')"
                                    class="form-text text-danger"
                                    >{{ errors.nik[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >NIP</label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <input
                                            type="text"
                                            v-model="form.nip"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': form.errors.has(
                                                    'nip'
                                                ),
                                            }"
                                            placeholder="Masukkan NIP"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Nama <span class="text-danger">*</span></label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input
                                            type="text"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': form.errors.has(
                                                    'nama'
                                                ),
                                            }"
                                            v-model="form.nama"
                                            placeholder="Masukkan Nama"
                                        />
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('nama')"
                                    class="form-text text-danger"
                                    >{{ errors.nama[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Jenis Kelamin
                                <span class="text-danger">*</span></label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-12 py-2">
                                        <div
                                            class="radio radio-css radio-inline"
                                        >
                                            <input
                                                type="radio"
                                                id="inlineCssRadio1"
                                                v-model="form.jenis_kelamin"
                                                value="laki-laki"
                                            />
                                            <label for="inlineCssRadio1"
                                                >Laki-laki</label
                                            >
                                        </div>
                                        <div
                                            class="radio radio-css radio-inline"
                                        >
                                            <input
                                                type="radio"
                                                id="inlineCssRadio1"
                                                v-model="form.jenis_kelamin"
                                                value="perempuan"
                                            />
                                            <label for="inlineCssRadio1"
                                                >Perempuan</label
                                            >
                                        </div>
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('jenis_kelamin')"
                                    class="form-text text-danger"
                                    >{{ errors.jenis_kelamin[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Tempat Lahir<span class="text-danger"
                                    >*</span
                                ></label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input
                                            type="text"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': form.errors.has(
                                                    'tmp_lahir'
                                                ),
                                            }"
                                            v-model="form.tmp_lahir"
                                            placeholder="Masukkan Tempat Lahir"
                                        />
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('tmp_lahir')"
                                    class="form-text text-danger"
                                    >{{ errors.tmp_lahir[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Tanggal Lahir
                                <span class="text-danger">*</span></label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="input-group">
                                            <date-picker
                                                id="dateTimeInput"
                                                v-model="form.tgl_lahir"
                                                :config="options"
                                                placeholder="-Pilih Tanggal-"
                                            ></date-picker>
                                            <div class="input-group-append">
                                                <label
                                                    for="dateTimeInput"
                                                    class="input-group-text"
                                                >
                                                    <i
                                                        class="fa fa-calendar"
                                                    ></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('tgl_lahir')"
                                    class="form-text text-danger"
                                    >{{ errors.tgl_lahir[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >No. Handphone
                                <span class="text-danger">*</span></label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input
                                            type="text"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': form.errors.has(
                                                    'nomp'
                                                ),
                                            }"
                                            v-model="form.nomp"
                                            placeholder="Masukkan Nomor Handphone"
                                        />
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('nomp')"
                                    class="form-text text-danger"
                                    >{{ errors.nomp[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Email <span class="text-danger">*</span></label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input
                                            type="text"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': form.errors.has(
                                                    'email'
                                                ),
                                            }"
                                            v-model="form.email"
                                            placeholder="Masukkan Email"
                                        />
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('email')"
                                    class="form-text text-danger"
                                    >{{ errors.email[0] }}</em
                                >
                            </div>
                        </div>

                        <h3 class="pt-2">Alamat</h3>
                        <hr />
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Alamat</label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="form.alamat"
                                            placeholder="Masukkan Alamat"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Provinsi</label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <v-select
                                            label="nama"
                                            :options="provinsiOptions"
                                            :reduce="(nama) => nama.id"
                                            v-model="input.provinsi"
                                            @input="onProvinsi(input.provinsi)"
                                            placeholder="Pilih Provinsi"
                                        >
                                        </v-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Kota/Kabupaten</label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <v-select
                                            label="nama"
                                            :options="kotaOptions"
                                            :reduce="(nama) => nama.id"
                                            v-model="input.kota"
                                            @input="onKabKota(input.kota)"
                                            placeholder="Pilih Kabupaten/Kota"
                                        ></v-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Kecamatan</label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <v-select
                                            label="nama"
                                            :options="kecamatanOptions"
                                            :reduce="(nama) => nama.id"
                                            v-model="input.kecamatan"
                                            @input="
                                                onKecamatan(input.kecamatan)
                                            "
                                            placeholder="Pilih Kecamatan"
                                        ></v-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Kelurahan</label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <v-select
                                            label="nama"
                                            :options="kelurahanOptions"
                                            :reduce="(nama) => nama.id"
                                            v-model="form.wilayah_kelurahan_id"
                                            placeholder="Pilih Kelurahan"
                                        ></v-select>
                                    </div>
                                </div>
                                <em
                                    v-if="
                                        form.errors.has('wilayah_kelurahan_id')
                                    "
                                    class="form-text text-danger"
                                    >{{ errors.wilayah_kelurahan_id[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Kodepos</label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <input
                                            type="text"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': form.errors.has(
                                                    'kodepos'
                                                ),
                                            }"
                                            v-model="form.kodepos"
                                            placeholder="Masukkan Kodepos"
                                        />
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('kodepos')"
                                    class="form-text text-danger"
                                    >{{ errors.kodepos[0] }}</em
                                >
                            </div>
                        </div>
                        <hr />
                        <div class="float-right">
                            <span>
                                <b-button
                                    variant="primary"
                                    type="submit"
                                    squared
                                    :disabled="isDisabled"
                                >
                                    <span v-if="isDisabled"
                                        ><b-spinner
                                            variant="light"
                                            small
                                            label="loading"
                                        ></b-spinner
                                    ></span>
                                    <i class="fa fa-save" v-else></i>
                                    Simpan
                                </b-button>
                            </span>
                        </div>
                    </b-form>
                </b-card>
            </div>
        </panel>
        <!-- Panel end here -->
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import axios from "axios";
// datetime
import moment from "moment";
import HRetribusi from "@/components/header/HRetribusi.vue";

export default {
    components: { HRetribusi },
    data() {
        return {
            form: new Form({
                nik: "",
                nip: "",
                nama: "",
                nomp: "",
                jenis_kelamin: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: "",
                kodepos: "",
                tmp_lahir: "",
                tgl_lahir: "",
            }),

            /* // variabel form rekening
            rekening: {
                kodeNama: '',
                kodeRekening: '0'
            }, */
            errors: [],
            message: null,

            // vue-select options
            npwpdOptions: [],
            provinsiOptions: [],
            kotaOptions: [],
            kecamatanOptions: [],
            kelurahanOptions: [],
            statusOptions: [
                {
                    id: 1,
                    nama: "Aktif",
                },
                {
                    id: 0,
                    nama: "Non Aktif",
                },
            ],
            statusSKOptions: [
                {
                    id: 1,
                    nama: "Pimpinan",
                },
                {
                    id: 2,
                    nama: "Penanggung Jawab",
                },
            ],

            // Variable wilayah
            input: {
                kota: null,
                kecamatan: null,
                provinsi: null,
            },
            // csrf token
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
            // datepicker config
            options: {
                format: "DD MMMM YYYY",
                useCurrent: false,
            },
            isDisabled: false,
        };
    },
    mounted() {
        this.getPegawai();
        this.getProvinsi();
    },
    methods: {
        // store data
        update() {
            this.isDisabled = true;
            this.form.tgl_lahir = this.backEndDateFormat(this.form.tgl_lahir);
            this.form
                .put(
                    "/api/data-induk/kepegawaian/pegawai/" +
                        this.$route.params.id
                )
                .then((response) => {
                    if (this.form.successful) {
                        this.isDisabled = false;
                        this.$swal({
                            icon: "success",
                            title: "Data berhasil diupdate",
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            confirmButtonText: "OKE",
                            showConfirmButton: true,
                            showCloseButton: true,
                            timer: 5000,
                        });
                        this.$router.push({ name: "ListPejabat" });
                        /*  this.form.reset()
                    this.kodeNama= ''
                    this.message = false */
                    }
                })
                .catch((error) => {
                    this.isDisabled = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.message = error.response.data.message;
                    } else if (error.response.status === 401) {
                        localStorage.removeItem("ER_token");
                        this.$router.push({ name: "Login" });
                    } else {
                        this.$swal({
                            title: "Tejadi Masalah",
                            icon: "error",
                            showCloseButton: true,
                            buttonsStyling: false,
                            showConfirmButton: true,
                            confirmButtonText: "OKE",
                            confirmButtonClass: "btn btn-primary",
                            timer: 5000,
                        });
                    }
                });
        },

        //Wilayah
        // saat provinsi di input
        onProvinsi(id) {
            if (this.input.provinsi === null) {
                this.kotaOptions = [];
                this.input.kota = null;
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
                this.kelurahanOptions = [];
                this.form.wilayah_kelurahan_id = null;
            } else {
                this.getKabupaten(id);
            }
        },
        // saat kab./kota di input
        onKabKota(id) {
            if (this.input.kota === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
                this.kelurahanOptions = [];
                this.form.wilayah_kelurahan_id = null;
            } else {
                this.getKecamatan(id);
            }
        },

        // saat kab./kota di input
        onKecamatan(id) {
            if (this.input.kecamatan === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
                this.kelurahanOptions = [];
                this.form.wilayah_kelurahan_id = null;
            } else {
                this.getKelurahan(id);
            }
        },
        // data detail pegawai
        getPegawai() {
            axios
                .get(
                    "/api/data-induk/kepegawaian/pegawai/" +
                        this.$route.params.id
                )
                .then((response) => {
                    const items = response.data.data;
                    this.form.fill(response.data.data);
                    this.input.provinsi =
                        items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi.id;
                    this.input.kota =
                        items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.id;
                    this.input.kecamatan =
                        items.wilayah_kelurahan.wilayah_kecamatan.id;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("ER_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // Provinsi
        getProvinsi() {
            axios
                .get("/api/data-induk/wilayah-indonesia/provinsi/getoption")
                .then((response) => {
                    this.provinsiOptions = response.data.data;
                    this.getKabupaten(this.input.provinsi);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("ER_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // Kabupaten
        getKabupaten(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kabkota/getoption/provinsi-" +
                        id
                )
                .then((response) => {
                    this.getKecamatan(this.input.kota);
                    this.kotaOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("ER_token");
                    }
                });
        },
        // Kecamatan
        getKecamatan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kecamatan/getoption/kabkota-" +
                        id
                )
                .then((response) => {
                    this.getKelurahan(this.input.kecamatan);
                    this.kecamatanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("ER_token");
                    }
                });
        },

        // Kelurahan
        getKelurahan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kelurahan/getoption/kecamatan-" +
                        id
                )
                .then((response) => {
                    this.kelurahanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("ER_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },

        // method helper
        frontEndDateFormat: function(date) {
            if (date) {
                return moment(date, "DD MMM YYYY").format("YYYY-MM-DD");
            } else {
                return null;
            }
        },
        backEndDateFormat: function(date) {
            if (date) {
                return moment(date, "DD MMMM YYYY").format("YYYY-MM-DD");
                // return new Date(date)
            } else {
                return null;
            }
        },

        /*  // reset
        reset () {
            this.form.reset()
            this.input.provinsi = null
            this.input.kota = null
            this.input.kecamatan = null
            this.input.kelurahan = null
        } */
    },
};
</script>
